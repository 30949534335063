import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { LayoutState } from '@redux/types/layout/layoutTypes';
import { getDataFromStorage, setDataToStorage } from '@shared/utils/common';

const storeName = 'layout';

const sideBarStatusStorageKey = 'sidebar_status';

const initialState: LayoutState = {
  navMenuCollapsed: getDataFromStorage(sideBarStatusStorageKey) === 'true',
};

const layoutSlice = createSlice({
  name: storeName,
  initialState,
  reducers: {
    toggleCollapsedNavMenu(state: LayoutState, action: PayloadAction<boolean>) {
      state.navMenuCollapsed = action.payload;
      setDataToStorage(sideBarStatusStorageKey, action.payload ? 'true' : 'false');
    },
  },
});

export const layoutActions = {
  toggleCollapsedNavMenu: layoutSlice.actions.toggleCollapsedNavMenu,
};

export const layoutReducer = layoutSlice.reducer;
