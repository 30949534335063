import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { MarketplaceMembersFilteringParams } from '@modules/marketplace-members/pages/marketplace-members-index';
import {
  MarketplaceMembersState,
  MarketplaceMembersSuccessPayload,
} from '@redux/types/marketplace-members/marketplaceMembersTypes';

const storeName = 'members';

const initialState: MarketplaceMembersState = {
  loading: false,
  list: [],
};

const marketplaceMembersSlice = createSlice({
  name: storeName,
  initialState,
  reducers: {
    setLoading(state: MarketplaceMembersState, action: PayloadAction<boolean>) {
      state.loading = action.payload;
      state.list = [];
      state.error = undefined;
    },
    getDataSuccess(state: MarketplaceMembersState, action: PayloadAction<MarketplaceMembersSuccessPayload>) {
      state.list = action.payload.data;
      state.pagination = action.payload.meta.pagination;
      state.loading = false;
      state.error = undefined;
    },
    getDataFailure(state: MarketplaceMembersState, action: PayloadAction<string>) {
      state.error = action.payload;
      state.loading = false;
      state.list = [];
    },
  },
});

export const marketplaceMembersActions = {
  setLoading: marketplaceMembersSlice.actions.setLoading,
  getDataSuccess: marketplaceMembersSlice.actions.getDataSuccess,
  getDataFailure: marketplaceMembersSlice.actions.getDataFailure,
  getDataRequest: createAction<MarketplaceMembersFilteringParams>(`${storeName}/getDataRequest`),
};

export const marketplaceMembersReducer = marketplaceMembersSlice.reducer;
