import { combineReducers } from 'redux';

import { browserEventReducer } from '@redux/reducers/browserEvent/browserEventReducer';
import campaignsReducer from '@redux/reducers/campaigns/campaignsReducer';
import commonReducer from '@redux/reducers/common/commonReducer';
import { configReducer } from '@redux/reducers/config/configReducer';
import crmReducer from '@redux/reducers/crm/crmReducer';
import diaryReducer from '@redux/reducers/diary/diaryReducer';
import disposalsReducer from '@redux/reducers/disposals/disposalsReducer';
import insightsReducer from '@redux/reducers/insights/InsightsReducer';
import { layoutReducer } from '@redux/reducers/layout/layoutReducer';
import { lookupReducer } from '@redux/reducers/lookup/lookupReducer';
import { marketplaceMembersReducer } from '@redux/reducers/marketplace-members/marketplaceMembersReducer';
import requirementsReducer from '@redux/reducers/requirements/requirementsReducer';
import savedViewsReducer from '@redux/reducers/saved_views/savedViewsReducer';
import userReducer from '@redux/reducers/user/userReducer';

const rootReducer = combineReducers({
  browserEvent: browserEventReducer,
  campaigns: campaignsReducer,
  common: commonReducer,
  config: configReducer,
  crm: crmReducer,
  diary: diaryReducer,
  disposals: disposalsReducer,
  insights: insightsReducer,
  layout: layoutReducer,
  lookup: lookupReducer,
  marketplaceMembers: marketplaceMembersReducer,
  requirements: requirementsReducer,
  savedViews: savedViewsReducer,
  user: userReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
