import { combineReducers } from 'redux';

import { manageTasksSliceReducer } from '@redux/reducers/diary/tasks/tasksReducer';


const diaryReducer = combineReducers({
  tasks: manageTasksSliceReducer,
});

export default diaryReducer;
