import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  CampaignsState,
  GetCampaignsRequestPayload,
  GetCampaignsSuccessPayload,
} from '@redux/types/campaigns/campaignsTypes';


const storeName = 'campaigns';

const initialState: CampaignsState = {
  data: [],
  loading: false,
};

const campaignsSlice = createSlice({
  name: storeName,
  initialState,
  reducers: {
    setCampaignsLoading(state: CampaignsState, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    getCampaignsSuccess(state: CampaignsState, action: PayloadAction<GetCampaignsSuccessPayload>) {
      state.data = action.payload.data;
      state.loading = false;
      state.error = undefined;
      state.meta = action.payload.meta;
    },
    getCampaignsFailure(state: CampaignsState, action: PayloadAction<string>) {
      state.error = action.payload;
      state.loading = false;
      state.data = [];
    },
  },
});

export const campaignsActions = {
  setCampaignsLoading: campaignsSlice.actions.setCampaignsLoading,
  getCampaignsSuccess: campaignsSlice.actions.getCampaignsSuccess,
  getCampaignsFailure: campaignsSlice.actions.getCampaignsFailure,
  getCampaignsRequest: createAction<GetCampaignsRequestPayload>(`${storeName}/getCampaignsRequest`),
};

export const campaignsReducer = campaignsSlice.reducer;
